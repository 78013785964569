const bp = {
  nHD: 768,
  HD: 1200,
  QHD: 1440,
  FHD: 1920,
  UHD: 3840,
}

const mq = n => {
  const bpArray = Object.keys(bp).map(key => [key, bp[key]])

  const [result] = bpArray.reduce((acc, [name, size]) => {
    if (n === name) return [...acc, `@media (min-width: ${size}px)`]
    return acc
  }, [])

  return result
}

export default mq
