import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import NavBar from "../components/navBar"
import mq from "../style/mediaQueries"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <div className="unblur">
        <div className="bg" />
        {/*  <NavBar /> */}
        <main>{children}</main>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px 20px;
  height: 100%;
  width: 100vw;
  ${mq("QHD")} {
    padding: 40px 80px;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
