import React from "react"
import styled from "@emotion/styled"
import mq from "../style/mediaQueries"
import { Link } from "gatsby"

const NavBar = () => {
  return (
    <Container className="appear">
      <LeftSide>
        <MenuItem>
          <Link cover bg="#2f2f2f" direction="right" duration={0.8} to={`/`}>
            Emanuele Dancelli
          </Link>
        </MenuItem>
      </LeftSide>
      <RightSide>
        <MenuItem style={{ marginRight: "1em" }}>
          <a
            href="https://github.com/emanueledancelli"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingBottom: "0.1rem" }}
          >
            GitHub
          </a>
        </MenuItem>
        <MenuItem style={{ marginRight: "1em" }}>
          <a
            href="https://www.linkedin.com/in/emanuele-dancelli-922868112/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingBottom: "0.1rem" }}
          >
            LinkedIn
          </a>
        </MenuItem>
        <MenuItem>
          <a
            href="mailto:work@emndnc.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ paddingBottom: "0.1rem" }}
          >
            Mail
          </a>
        </MenuItem>
      </RightSide>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  opacity: 0;
  top: 0;
  left: 20;
  width: calc(100vw - 40px);
  height: 60px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 100;
  ${mq("QHD")} {
    position: absolute;
    margin: 0 auto;
    width: calc(100vw - 160px);
    left: 80;
  }
`

const LeftSide = styled.ul`
  display: flex;
  margin: 0;
  justify-content: space-between;
  & > span {
    font-weight: 500;
  }
`
const MenuItem = styled.li`
  list-style: none;
  margin: 0;
  font-size: 15px;

  & > a {
    color: #000;
    font-size: 16px;
  }
  & a:hover {
    //to add
  }
  ${mq("QHD")} {
    & > a {
      color: #000;
      font-size: 16px;
    }
  }
`

const RightSide = styled.ul`
  display: none;
  margin: 0;
  & > a {
    color: #3f3f3f;
    font-size: 15px;
  }
  ${mq("nHD")} {
    display: flex;
    justify-content: flex-end;
  }
`

export default NavBar
